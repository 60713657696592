<template>
  <div class="about">
    <div>
      <b-modal ref="signin-modal" title="Sign In Failed" hide-footer>
        <div class="d-block text-center">
          <h3>Sign In Failed</h3>
          <p>Press Close and try again.</p>
        </div>
        <b-button class="mt-3" block @click="hideModal">Close</b-button>
      </b-modal>

      <b-alert class="mt-3" :show="this.error_message != null" dismissible variant="danger">{{ this.error_message }}</b-alert>

      <b-card-group deck class="mt-3">
        <b-card
          header="Sign in to use this portal."
          header-tag="header"
          title=""
        >
          <p>If you are having trouble signing in or need a new account created, please create a <b-link to="help">support request</b-link>.</p>
          <b-alert
            variant="info"
            dismissible
            fade
            :show="show_passcode_sent_alert==true"
            @dismissed="show_passcode_sent_alert=false"
          >
          Sign in code sent to {{ this.signin_email_address }}. &nbsp;
          Check your e-mail for the code and enter it in the "Sign In Code" field below.  This code will be valid for 5 minutes.
          </b-alert>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form id="signin-form" @submit.stop.prevent="handleSubmit(onSignInWithCode)">
            <!-- E-Mail -->
            <validation-provider
              name="EMail Validation"
              rules="email|required"
              v-slot="validationContext"
            >
            <b-form-group
              id="signin-email-group"
              label="Your E-Mail Address"
              label-for="signin-email"
            >
              <b-form-input
                v-model="signin_email_address"
                type="email"
                :state="getEmailValidationState(validationContext)"
                required
                id="signin-email"
              ></b-form-input>
            </b-form-group>
          </validation-provider>
          <b-button
            class="mb-3"
            :disabled="send_passcode_btn_disabled"
            variant="primary"
            @click="onSendSignInCode"
          >
            <span v-if="send_passcode_count >= send_passcode_max_count">Max Resends Met</span>
            <span v-else-if="send_passcode_count > 0">Resend Code</span>
            <span v-else>Send Me A Code</span>
          </b-button>
            <!-- Passcode -->
            <b-form-group
              id="signin-passcode-group"
              label="Sign In Code"
              label-for="signin-passcode"
            >
              <b-input
                id="signin-passcode"
                v-model="signin_passcode"
                required
                type="number"
                placeholder=""
              ></b-input>
            </b-form-group>
            <b-form-checkbox v-model="stay_signed_in" class="my-3">
              Stay Signed In (Do not check this if using a shared device.)
            </b-form-checkbox>
            <b-button type="submit" variant="primary" :disabled="signin_passcode.length !== 6">Sign In</b-button>
          </b-form>
        </validation-observer>
        </b-card>
      </b-card-group>




      <b-modal
        ref="modal-get-code"
        id="modal-get-code"
        okOnly
        okTitle="All Done"
        title="Get a Sign In Code"
      >
        <p>
          If you need a sign in code for PWS {{ signin_pws_id }}, enter the
          email address on file at MSDH and we will send a code to that address.
        </p>
        <b-form @submit="onSendCode">
          <b-form-group
            id="fg-email-address"
            label="Email Address on File"
            label-for="email-address"
          >
            <b-form-input
              id="email-address"
              v-model="signin_email_address"
              type="email"
              required
            ></b-form-input>
          </b-form-group>
          <!--null or false is ok here -->
          <b-button :disabled="send_passcode_btn_disabled" type="submit" variant="primary"
            >Send Code</b-button
          >
        </b-form>
        <b-alert class="mt-3" :show="signin_passcode_sent" variant="success">
          A code was sent to {{ signin_email_address }}. Check your email.
        </b-alert>
        <b-alert
          class="mt-3"
          :show="signin_passcode_sent == false"
          variant="danger"
        >
          The code was not sent. Check that the address is correct.
        </b-alert>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    console.log("Component mounted.")
  },
  computed: {
    pwsidState() {
      if (this.signin_pws_id.length == 7) {
        return true
      }
      if (this.signin_pws_id.length == 0) {
        return null
      } else {
        return false
      }
    }
  },
  data() {
    return {
      signin_passcode: "",
      signin_passcode_sent: null,
      stay_signed_in: false,
      show_passcode_sent_alert: false,
      send_passcode_btn_disabled: false,
      send_passcode_count: 0,
      send_passcode_max_count: 3,
      signin_email_address: "",
      signin_email_address_valid: null,
      signin_pws_id: "",
      session_pws_id: "",
      signin_role_options: [
        { value: "PC", text: "Primary Contact" },
        { value: "AC", text: "Administrative Contact" },
        { value: "DO", text: "Designated Operator" }
      ],
      signin_role_selected: "PC",
      error_message: null
    }
  },
  methods: {
    hideModal() {
      this.$refs["signin-modal"].hide()
    },
    showSendCodeModal() {
      this.send_passcode_btn_disabled = false
      this.signin_passcode_sent = null
      this.signin_email_address = ""
      this.$refs['modal-get-code'].show()
    },
    getEmailValidationState({ dirty, validated, valid = null }) {
      this.signin_email_address_valid = valid
      let pwc = this.send_passcode_count < this.send_passcode_max_count
      switch(valid && pwc) {
        case true:
          this.send_passcode_btn_disabled = false
          break
        default:
          this.send_passcode_btn_disabled = true
      }
      return dirty || validated ? valid : null;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async onSendSignInCode() {
      console.log('Attempting to send code.')
      this.error_message = null
      const path = process.env.VUE_APP_API_URI + "/accounts/us-signin/send-code"
      const params = {identity: this.signin_email_address, chosen_method: 'email'}
      try {
        const res = await this.axios.post(path, params)
        console.log(res)
        if (res.data.meta.code == 200) {
          console.log('code sent')
          console.log('csrf_token: ' + res.data.response.csrf_token)
          this.$store.commit('set_csrf_token', res.data.response.csrf_token)
        }
        else {
          let error = res.data.response.error
          console.log(error)
        }
        this.signin_passcode_sent = true
        this.show_passcode_sent_alert = true
        this.send_passcode_count += 1
        if (this.send_passcode_count > this.send_passcode_max_count) {
          this.send_passcode_btn_disabled = true
        }
        console.log(this.send_passcode_btn_disabled)
        document.getElementById('signin-passcode').focus()
      } catch (err) {
        console.log('caught error')
        console.log(err.response)
        if (err.response.data.meta.code == 400) {
          console.log(err.response.data.response.errors)
          let errors = err.response.data.response.errors
          if (errors.identity) {
            //this.error_message = 'E-Mail Address Not Found'
            if (errors.identity[0] == 'You must specify a valid identity to sign in'){
              this.error_message = 'E-Mail Address Not Found'
            }
            else {
              this.error_message = errors.identity[0]
            }
          }
          else {
            this.error_message = errors
          }
        }
        else {
          this.error_message = "Network Error.  Please try again."
        }
        console.error(err)
      }
    },
    async onSignInWithCode() {
      console.log('attempting sign in')
      this.error_message = null
      const path = process.env.VUE_APP_API_URI + "/accounts/us-signin"
      console.log(this.stay_signed_in)
      const params = {identity: this.signin_email_address, passcode: this.signin_passcode, remember: this.stay_signed_in}
      //const params = new URLSearchParams()
      //params.append('identity', this.signin_email_address)
      //params.append('passcode', this.signin_passcode)
      ////params.append('remember', this.stay_signed_in)
      //params.append('remember', false)
      //const headers =  {'Content-type': 'application/x-www-form-urlencoded',}
      try {
        const res = await this.axios.post(path, params)
        console.log(res)
        if (res.data.meta.code == 200) {
          console.log('signed in')
          console.log('csrf_token: ' + res.data.response.csrf_token)
          //await this.getSession()
          this.$router.push({ name: "LoadUserData" })
        }
        else {
          this.error_message = "Network Error.  Please try again."
          console.log('error sending sign in code')
          console.log(res.data.response.error)
        }
      } catch (err) {
        console.log('caught error')
        console.log(err.response)
        if (err.response.data.meta.code == 400) {
          console.log(err.response.data.response.errors)
          let errors = err.response.data.response.errors
          this.error_message = errors
          if (errors.identity) {
            //this.error_message = 'E-Mail Address Not Found'
            if (errors.identity[0] == 'You must specify a valid identity to sign in'){
              this.error_message = 'E-Mail Address Not Found'
            }
            else {
              this.error_message = errors.identity[0]
            }
          }
          else {
            this.error_message = errors
          }
        }
        else {
          this.error_message = "Network Error.  Please try again."
        }
        console.error(err)
      }
    },
    // Duped in Signin - Need to Refactor
    async getPWS() {
      console.log("Fetching Water Systems")
      const path = process.env.VUE_APP_API_URI + "/get-pws-for-user"
      try {
        const res = await this.axios.get(path, { withCredentials: true }) 
        console.log(res)
        if (res.data.water_systems) {
          console.log('saving water system list')
          console.log(res.data.water_systems)
          this.$store.commit("set_roles_water_systems", res.data.water_systems)
        } else {
          console.log("no water systems")
        }
      } catch(error) {
        console.error(error)
        this.error_message = 'Error fetching Water Systems.  Trying reloading page.'
      }
    },
    async getSession() {
      console.log("checking session")
      const path = process.env.VUE_APP_API_URI + "/getsession"
      try {
        const res = await this.axios.get(path, { withCredentials: true }) 
        console.log(res)
        if (res.data.user) {
          this.$store.commit("set_user", res.data.user)
          this.$store.commit("set_role", res.data.user.roles[0])
          this.$store.commit("set_signed_in", true)
          await this.getPWS()
          // set default PWS
          if (this.$store.state.roles_water_systems && ! this.$store.state.pws) {
            const df_pws = this.$store.getters.default_pws
            this.$store.commit('set_pws', df_pws)
            this.$store.commit('set_pws_id', df_pws.pws_id)
          }
        } else {
          console.log("session was empty")
        }

      } catch(error) {
        console.error(error)
        this.error_message = 'Error fetching session.  Trying reloading page.'
      }
      /*
      this.axios
        .get(path, { withCredentials: true })
        .then((res) => {
          console.log(res)
          if (res.data.user) {
            //this.pws_user_id = res.data.pws_id;
            //this.$store.commit("set_pws_id", res.data.pws_id)
            this.$store.commit("set_first_name", res.data.user.first_name)
            this.$store.commit("set_last_name", res.data.user.last_name)
            this.$store.commit("set_role", res.data.user.roles[0])
            this.$store.commit("set_signed_in", true)
            //this.$router.push({ name: 'Home'})
          } else {
            console.log("not logged in")
          }
        })
        .catch((error) => {
          console.error(error)
        })
       */
    },
    onSendCode(e) {
      e.preventDefault()
      this.send_passcode_btn_disabled = true
      const path = process.env.VUE_APP_API_URI + "/signin-code"
      this.axios
        .get(path, {params: {
          pws_id: this.signin_pws_id,
          email_address: this.signin_email_address,
        }})
        .then((res) => {
          console.log(res)
          if (res.data.message_sent) {
            this.signin_passcode_sent = true
            this.send_passcode_btn_disabled = true
          }
          else {
            this.signin_passcode_sent = false
            this.send_passcode_btn_disabled = false
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    onSignIn(e) {
      e.preventDefault()
      const path = process.env.VUE_APP_API_URI + "/signin"
      console.log("signin_pws_id:", this.signin_pws_id)
      this.axios
        .post(path, {
          pws_id: this.signin_pws_id,
          role: this.signin_role_selected,
          passcode: this.signin_passcode,
          withCredentials: true
        })
        .then((res) => {
          console.log(res)
          if (res.data.auth) {
            this.$store.commit("set_pws_id", this.signin_pws_id)
            this.$store.commit("set_role", this.signin_role_selected)
            this.$store.commit("set_signed_in", true)
            //if ("persist:store" in localStorage) {
            //  console.log("getting data from persist")
            //  let jdata = JSON.parse(localStorage.getItem("persist:store"))
            //  let old_pws_id = jdata.data.form1.pws_id
            //  console.log("old pws is" + old_pws_id)
            //  if (old_pws_id !== this.signin_pws_id) {
            //    console.log("clearing persist store")
            //    localStorage.removeItem("persist:store")
            //  }
            //}
            this.$router.push({ name: "Home" })
          } else {
            console.log("auth failed")
            this.$refs["signin-modal"].show()
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
}
</script>
